import './components/styles/styles.css';

import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import { AdBlockDetectedWrapper } from 'adblock-detect-react';
import CoursePage from './components/CoursePage';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import NoMatch from './components/NoMatch';
import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-EKWFGLGKVR';
ReactGA.initialize(TRACKING_ID);

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <AdBlockDetectedWrapper>
          <div className='detected'>
            Please consider disabling Ad-Blocker to help us maintain this
            project
          </div>
        </AdBlockDetectedWrapper>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route
            path='/OS'
            exact
            component={() => (
              <CoursePage
                title='Operating Systems'
                doctor='Dr. Ahmed Salah'
                abb='OS'
                teams='4fq1z54'
              />
            )}
          />
          <Route
            path='/CN'
            exact
            component={() => (
              <CoursePage
                title='Computer Networks'
                abb='CN'
                doctor='Dr. Mohamed Hashem'
              />
            )}
          />
          <Route
            path='/SA'
            exact
            component={() => (
              <CoursePage
                title='System Analysis & Design'
                abb='SA'
                doctor='Dr. Yasmine Afify'
              />
            )}
          />
          <Route
            path='/DM'
            exact
            component={() => (
              <CoursePage
                title='Data Mining'
                abb='DM'
                doctor='Dr. Wedad Hussein'
              />
            )}
          />
          <Route
            path='/CT'
            exact
            component={() => (
              <CoursePage
                title='Compiler Theory'
                abb='CT'
                doctor='Dr. Sally Saad'
              />
            )}
          />
          <Route
            path='/NC'
            exact
            component={() => (
              <CoursePage
                title='Numerical Computing'
                abb='NC'
                doctor='Dr. Safaa Amin'
              />
            )}
          />
          <Route
            path='/SI'
            exact
            component={() => (
              <CoursePage
                title='Statistical Inference'
                abb='SI'
                doctor='Dr. Mahmoud Mounir'
              />
            )}
          />
          <Route
            path='/DSP'
            exact
            component={() => (
              <CoursePage
                title='Digital Signal Processing (CS)'
                abb='DSP'
                doctor='Dr. Manal Tantawy'
              />
            )}
          />
          <Route
            path='/DSPS'
            exact
            component={() => (
              <CoursePage
                title='Digital Signal Processing (CSys)'
                abb='DSPS'
                doctor='Dr. Noha AbdelSabour'
              />
            )}
          />
          <Route
            path='/MI'
            exact
            component={() => (
              <CoursePage
                title='Microprocessors & Interfacing'
                abb='MI'
                teams='8rbudlr'
                doctor='Dr. Randa Mohamed'
              />
            )}
          />
          <Route path='*' component={NoMatch} />
        </Switch>
        <Footer year='2021' />
      </Router>
    </>
  );
};

export default App;
