import { useEffect, useState } from 'react';

import CourseLables from './CourseLables';
import CourseTitle from './CourseTitle';
import { FilterContext } from '../FilterContext';

const Courses = () => {
  const initial = window.localStorage.getItem('filter');
  const [filter, setFilter] = useState(initial || 'all');

  useEffect(() => {
    window.localStorage.setItem('filter', filter);
  }, [filter]);

  return (
    <FilterContext.Provider value={{ filter, setFilter }}>
      <CourseLables />
      <CourseTitle name='Operating Systems' to='OS' cs sc is sys />
      <CourseTitle name='Computer Networks' to='CN' cs sc is sys />
      <CourseTitle name='System Analysis & Design' to='SA' cs sc is sys wrp />
      {(filter === 'all' || filter === 'CS' || filter === 'IS') && (
        <CourseTitle name='Data Mining' to='DM' cs is />
      )}
      {(filter === 'all' || filter === 'CS') && (
        <CourseTitle name='Compiler Theory' to='CT' cs />
      )}
      {(filter === 'all' || filter === 'SC') && (
        <CourseTitle name='Numerical Computing' to='NC' sc />
      )}
      {(filter === 'all' || filter === 'SC') && (
        <CourseTitle name='Digital Signal Processing (SC)' to='DSP' ys sc />
      )}
      {(filter === 'all' || filter === 'IS') && (
        <CourseTitle name='Statistical Inference' to='SI' is />
      )}
      {(filter === 'all' || filter === 'CSys') && (
        <CourseTitle name='Microprocessors & Interfacing' to='MI' sys wrp />
      )}
      {(filter === 'all' || filter === 'CSys') && (
        <CourseTitle
          name='Digital Signal Processing (CSys)'
          to='DSPS'
          sys
          wrp
        />
      )}
    </FilterContext.Provider>
  );
};

export default Courses;
