import './styles/Home.css';

import Announcement from './Announcement';
import Courses from './Courses';
import Dock from 'react-dock';
import Issues from './Issues';
import Notification from './Notification';
import ReactGA from 'react-ga4';
import firebase from '../firebase';
import { useEffect } from 'react';
import { useState } from 'react';

const Home = () => {
  const [notifications, setNotifications] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [newNotif, setNewNotif] = useState(false);
  const [newAnnouncement, setNewAnnouncement] = useState(false);

  const convertSecondsToDate = (seconds) => {
    const date = new Date(0);
    date.setUTCSeconds(seconds);
    const amOrPm = date.getHours() >= 12 ? 'PM' : 'AM';
    let hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    // prettier-ignore
    return (
      `0${hours}`.slice(-2) +
      ':' +
      `0${date.getMinutes()}`.slice(-2) +
      ' ' +
      amOrPm +
      ' / ' +
      date.getDate() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getFullYear()
    );
  };

  const fetchNotifAndAnnouncements = async () => {
    const db = firebase.firestore();
    const data = await db
      .collection('notifications')
      .orderBy('createdAt', 'desc')
      .get();
    window.localStorage.setItem(
      'notifications',
      JSON.stringify(data.docs.map((doc) => doc.data()))
    );

    const data2 = await db
      .collection('announcements')
      .orderBy('postedAt', 'desc')
      .get();
    window.localStorage.setItem(
      'announcements',
      JSON.stringify(data2.docs.map((doc) => doc.data()))
    );
  };

  const updateLocalStorage = () => {
    var time2 = Date.now();
    if (
      window.localStorage.getItem('lastfetchedNotifs') === null ||
      window.localStorage.getItem('lastfetchedNotifs') < time2 - 1800000
    ) {
      fetchNotifAndAnnouncements();
      window.localStorage.setItem('lastfetchedNotifs', time2);
    }
  };

  useEffect(() => {
    document.title = 'FCIS 2023 - 5th Semester';
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname,
      title: `FCIS 2023 - 5th Semester`,
    });
    updateLocalStorage();
    const notfs = JSON.parse(window.localStorage.getItem('notifications'));
    const anns = JSON.parse(window.localStorage.getItem('announcements'));
    if (notfs && anns) {
      setNotifications(notfs);
      const notifNum = localStorage.getItem('notifNum');
      if (notfs.length > notifNum) {
        setNewNotif(true);
      }
      setAnnouncements(anns);
      const announcementsNum = localStorage.getItem('announcementsNum');
      if (anns.length > announcementsNum) {
        setNewAnnouncement(true);
      }
    } else {
      fetchNotifAndAnnouncements().then(() => {
        setNotifications(notfs);
        const notifNum = localStorage.getItem('notifNum');
        if (notfs.length > notifNum) {
          setNewNotif(true);
        }
        setAnnouncements(anns);
        const announcementsNum = localStorage.getItem('announcementsNum');
        if (anns.length > announcementsNum) {
          setNewAnnouncement(true);
        }
      });
    }
    // fetchNotifAndAnnouncements();
  }, []);

  const [dockVisible, setDockVisible] = useState(false);
  const [announcementDockVisible, setAnnouncementDockVisible] = useState(false);
  const handleDockClick = () => {
    setDockVisible(!dockVisible);
    setNewNotif(false);
    localStorage.setItem('notifNum', notifications.length);
  };
  const handleAnnouncement = () => {
    setAnnouncementDockVisible(!announcementDockVisible);
    if (newAnnouncement) setNewAnnouncement(false);
    localStorage.setItem('announcementsNum', announcements.length);
  };

  return (
    <div className='content'>
      <i className='fas fa-bell' onClick={handleDockClick}></i>
      {newNotif && <i className='fas fa-circle alert_circle'></i>}
      <i className='fas fa-exclamation' onClick={handleAnnouncement}></i>
      {newAnnouncement && <i className='fas fa-circle alert_circle_2'></i>}

      <Dock
        className='dock'
        isVisible={announcementDockVisible}
        position='left'
        dimMode='opaque'
        size={window.innerWidth > 600 ? 450 : window.innerWidth}
        dockStyle={{
          padding: '15px',
          background: 'whitesmoke',
          color: '#111',
        }}
        fluid={false}>
        <div className='wrapper'>
          <h3>Announcements</h3>
          <i className='fas fa-times' onClick={handleAnnouncement}></i>
        </div>

        <ul>
          {announcements?.map((announcement) => (
            <Announcement
              key={announcement.postedAt?.seconds}
              link={announcement.link}
              linkText={announcement.linkText}
              text={announcement.text}
              person={announcement.postedBy}
              date={convertSecondsToDate(announcement.postedAt.seconds)}
            />
          ))}
        </ul>
      </Dock>

      <Dock
        className='dock'
        isVisible={dockVisible}
        position='left'
        dimMode='opaque'
        size={window.innerWidth > 600 ? 450 : window.innerWidth}
        dockStyle={{
          padding: '15px',
          background: 'whitesmoke',
          color: '#111',
        }}
        fluid={false}>
        <div className='wrapper'>
          <h3>Notifications</h3>
          <i className='fas fa-times' onClick={handleDockClick}></i>
        </div>

        <ul>
          {notifications?.map((notification) => (
            <Notification
              key={notification.createdAt?.seconds}
              text={notification.text}
              date={convertSecondsToDate(notification.createdAt.seconds)}
            />
          ))}
        </ul>
      </Dock>

      <a className='banner' href='https://www.instagram.com/stiqastore/'>
        <img
          src='https://res.cloudinary.com/omar45/image/upload/v1688329814/Stiqa/fcis-banner.webp'
          alt='stiqa store'
          target='_blank'
        />
      </a>

      <Courses />
      <Issues />
    </div>
  );
};

export default Home;
