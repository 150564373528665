import 'firebase/firestore';

import firebase from 'firebase/app';

const config = {
  apiKey: 'AIzaSyCP2exg7Pd_wryScB2w5XQa_4OH6QSpWV4',
  authDomain: 'fcis5th.firebaseapp.com',
  projectId: 'fcis5th',
  storageBucket: 'fcis5th.appspot.com',
  messagingSenderId: '148640996427',
  appId: '1:148640996427:web:332fd2049a97e01ec7c1ce',
};
// Initialize Firebase
firebase.initializeApp(config);

export default firebase;
