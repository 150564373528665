import './styles/CourseLables.css';

import { FilterContext } from '../FilterContext';
import { useContext } from 'react';

const CourseLables = () => {
  const { filter, setFilter } = useContext(FilterContext);
  return (
    <div className='labels'>
      <h3
        onClick={() => (filter === 'CS' ? setFilter('all') : setFilter('CS'))}>
        <i className='fas fa-circle' style={{ color: '#ea2027' }}></i>
        Computer Science
      </h3>
      <h3
        onClick={() => (filter === 'SC' ? setFilter('all') : setFilter('SC'))}>
        <i className='fas fa-circle' style={{ color: '#78D237' }}></i>
        Scientific Computing
      </h3>
      <h3
        onClick={() => (filter === 'IS' ? setFilter('all') : setFilter('IS'))}>
        <i className='fas fa-circle' style={{ color: '#FFD246' }}></i>
        Information Systems
      </h3>
      <h3
        onClick={() =>
          filter === 'CSys' ? setFilter('all') : setFilter('CSys')
        }>
        <i className='fas fa-circle' style={{ color: '#2D73F5' }}></i>
        Computer Systems
      </h3>
    </div>
  );
};

export default CourseLables;
