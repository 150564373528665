import './styles/Notification.css';

const Notification = ({ text, date }) => {
  return (
    <li className='notif'>
      <h1>{text}</h1>
      <p>{date}</p>
    </li>
  );
};

export default Notification;
